import { Container, Form, SpaceBetween } from '@cloudscape-design/components';
import { ErrorObject } from '@hookform/resolvers/io-ts';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import Button from '@risksmart-app/components/Button';
import renderers from '@risksmart-app/components/FormBuilder/renderers/registry';
import { useFormBuilderStore } from '@risksmart-app/components/FormBuilder/store/useFormBuilderStore';
import { useNotifications } from '@risksmart-app/components/Notifications/useNotifications';
import { FC, FormEvent, FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ResponseData } from './types';

enum QUESTIONNAIRE_FORM_SUBMIT_TYPE {
  SUBMIT = 'submit',
  DRAFT = 'draft',
  NONE = 'none',
}

interface QuestionnaireFormUnwrappedProps {
  readOnly?: boolean;
  onCancel: () => void;
  onSubmit: (data: ResponseData) => Promise<void>;
  onSave: (data: ResponseData) => Promise<void>;
  values: ResponseData;
  schema: JsonSchema;
  uischema: UISchemaElement;
}

export const QuestionnaireForm: FC<QuestionnaireFormUnwrappedProps> = ({
  readOnly,
  onCancel,
  onSubmit,
  onSave,
  values,
  schema,
  uischema,
}) => {
  const [data, setData] = useState<ResponseData>(values);
  const [errors, setErrors] = useState<ErrorObject[] | undefined>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation(['common'], {
    keyPrefix: 'third_party_responses.questionnaire_form',
  });

  const [submitType, setSubmitType] = useState<QUESTIONNAIRE_FORM_SUBMIT_TYPE>(
    QUESTIONNAIRE_FORM_SUBMIT_TYPE.NONE
  );

  const { addNotification } = useNotifications();
  const { setIsFormDirty } = useFormBuilderStore();

  const onSaveFn = async () => {
    setIsSubmitting(true);
    setIsFormDirty(true);

    await onSave({
      ...data,
    });

    setIsSubmitting(false);
    addNotification({
      type: 'success',
      content: t('notification.save_success'),
    });
  };

  const onSubmitFn: FormEventHandler<HTMLFormElement> = async (
    event: FormEvent
  ) => {
    event.preventDefault();
    setIsSubmitting(true);
    setIsFormDirty(true);

    if (errors?.length && errors.length > 0) {
      addNotification({
        type: 'error',
        content: t('notification.submit_error'),
      });

      setIsSubmitting(false);

      return;
    }

    addNotification({
      type: 'success',
      content: t('notification.submit_success'),
    });

    await onSubmit(data);
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={onSubmitFn}>
      <Container>
        <Form
          actions={<></>}
          secondaryActions={
            <SpaceBetween
              size={'xs'}
              direction={'horizontal'}
              alignItems={'start'}
            >
              {!readOnly ? (
                <>
                  <Button
                    variant={'primary'}
                    formAction={'submit'}
                    disabled={isSubmitting}
                    onClick={() =>
                      setSubmitType(QUESTIONNAIRE_FORM_SUBMIT_TYPE.SUBMIT)
                    }
                  >
                    {t('submit_button_label')}
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    formAction={'none'}
                    onClick={() => {
                      setSubmitType(QUESTIONNAIRE_FORM_SUBMIT_TYPE.DRAFT);
                      onSaveFn();
                    }}
                  >
                    {t('save_button_label')}
                  </Button>
                </>
              ) : (
                <></>
              )}
              <Button
                variant="normal"
                disabled={isSubmitting}
                formAction="none"
                onClick={onCancel}
              >
                {readOnly ? t('back_button_label') : t('cancel_button_label')}
              </Button>
            </SpaceBetween>
          }
        >
          <JsonForms
            schema={schema}
            uischema={uischema}
            data={data}
            renderers={renderers}
            readonly={readOnly}
            onChange={({ data, errors }) => {
              setErrors(errors as unknown as ErrorObject[]);
              setData(data);
            }}
            validationMode={
              submitType === QUESTIONNAIRE_FORM_SUBMIT_TYPE.SUBMIT
                ? 'ValidateAndShow'
                : 'ValidateAndHide'
            }
          />
        </Form>
      </Container>
    </form>
  );
};
